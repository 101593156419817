//
// Component: Sidebar Mini
//

// Logo style
.logo-xs,
.logo-xl {
  opacity: 1;
  position: absolute;
  visibility: visible;

  &.brand-image-xs {
    left: 18px;
    top: 12px;
  }

  &.brand-image-xl {
    left: 12px;
    top: 6px;
  }
}

.logo-xs {
  opacity: 0;
  visibility: hidden;

  &.brand-image-xl {
    left: 16px;
    top: 8px;
  }
}

.brand-link {
  &.logo-switch {
    &::before {
      content: '\00a0';
    }
  }
}

// Add sidebar-mini class to the body tag to activate this feature
.sidebar-mini {
  @include media-breakpoint-up(lg) {
    @include sidebar-mini-breakpoint;
  }
}
@include media-breakpoint-down(md) {
  .sidebar-mini.sidebar-collapse .main-sidebar {
    box-shadow: none !important;
  }
}

.sidebar-mini-md {
  @include media-breakpoint-up(md) {
    @include sidebar-mini-breakpoint;
  }
}
@include media-breakpoint-down(sm) {
  .sidebar-mini-md.sidebar-collapse .main-sidebar {
    box-shadow: none !important;
  }
}

.sidebar-collapse {
  .main-sidebar.sidebar-focused,
  .main-sidebar:hover {
    .nav-header {
      display: inline-block;
    }
  }

  .sidebar-no-expand.main-sidebar.sidebar-focused,
  .sidebar-no-expand.main-sidebar:hover {
    width: $sidebar-mini-width;

    .nav-header {
      display: none;
    }

    .brand-link {
      width: $sidebar-mini-width !important;
    }

    .user-panel .image {
      float: none !important;
    }

    .logo-xs {
      opacity: 1;
      visibility: visible;
    }

    .logo-xl {
      opacity: 0;
      visibility: hidden;
    }

    .nav-sidebar.nav-child-indent .nav-treeview {
      padding-left: 0;
    }

    .brand-text,
    .user-panel > .info,
    .nav-sidebar .nav-link p {
      margin-left: -10px;
      opacity: 0;
      visibility: hidden;
      width: 0;
    }

    .nav-sidebar > .nav-item .nav-icon {
      margin-right: 0;
    }

    .nav-flat {
      .nav-icon {
        margin-left: .5rem;
      }

      .nav-treeview {
        .nav-icon {
          margin-left: .3rem;
        }
      }
    }

    .nav-flat.nav-compact {
      .nav-icon {
        margin-left: 1.05rem;
      }

      .nav-treeview {
        .nav-icon {
          margin-left: .85rem;
        }
      }
    }
  }
}

.nav-sidebar {
  position: relative;

  &:hover {
    overflow: visible;
  }
}

.sidebar-form,
.nav-sidebar > .nav-header {
  overflow: hidden;
  text-overflow: clip;
}

.nav-sidebar .nav-item > .nav-link {
  position: relative;

  > .float-right {
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
  }
}

.sidebar .nav-link p,
.main-sidebar .brand-text,
.main-sidebar .logo-xs,
.main-sidebar .logo-xl,
.sidebar .user-panel .info {
  @include transition(margin-left $transition-speed linear, opacity $transition-speed ease, visibility $transition-speed ease)
}
